import React, { useReducer } from 'react';
import ClosureInformationContext from './closureInformationContext';
import ClosureInformationReducer from './closureInformationReducer';
import api from '../../api/api';
import {
    GET_CLOSUREINFORMATIONS,
    GET_CLOSUREINFORMATION,
    CREATE_CLOSUREINFORMATION,
    DELETE_CLOSUREINFORMATION,
    UPDATE_CLOSUREINFORMATION,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';
import { handleError } from '../utils/handleError';

const ClosureInformationState = (props) => {
    const initialState = {
        closureInformations: [],
        closureInformation: {},
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(
        ClosureInformationReducer,
        initialState
    );

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/closureInformation/closuresAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_CLOSUREINFORMATIONS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Recordings by store
    const getClosureInformationsByStore = async (
        stores,
        pagination,
        query,
        typeQuery,
        tab
    ) => {
        setLoading();
        try {
            let res;
            if (tab === 'all')
                res = await api.get(
                    `/closureInformation?${stores}&page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            if (tab === 'success')
                res = await api.get(
                    `/closureInformation?${stores}&page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=success`,
                    HEADERS()
                );
            if (tab === 'canceled')
                res = await api.get(
                    `/closureInformation?${stores}&page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=canceled`,
                    HEADERS()
                );

            dispatch({
                type: GET_CLOSUREINFORMATIONS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get ClosureInformations
    const getClosureInformations = async (
        pagination,
        query,
        typeQuery,
        tab
    ) => {
        setLoading();

        try {
            if (!query) query = '';

            let res;
            if (tab === 'all')
                res = await api.get(
                    `/closureInformation/closuresAr?page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            if (tab === 'success')
                res = await api.get(
                    `/closureInformation/closuresAr?page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=success`,
                    HEADERS()
                );
            if (tab === 'canceled')
                res = await api.get(
                    `/closureInformation/closuresAr?page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=canceled`,
                    HEADERS()
                );

            dispatch({
                type: GET_CLOSUREINFORMATIONS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get ClosureInformation
    const getClosureInformation = async (closureInformationId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `/closureInformation/${closureInformationId}`,
                HEADERS()
            );
            dispatch({ type: GET_CLOSUREINFORMATION, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get ClosureInformation
    const getClosureInformationByLead = async (leadId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(
                `leads/${leadId}/closureInformation`,
                HEADERS()
            );
            dispatch({ type: GET_CLOSUREINFORMATION, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create ClosureInformation
    const createClosureInformation = async (closureInformation, file) => {
        clearState();
        setLoading();
        try {
            let res;
            if (file) {
                const uploadConfig = await api.post(
                    '/uploads/image',
                    { type: file.type, fileName: file.name },
                    HEADERS()
                );

                await axios.put(uploadConfig.data.url, file, {
                    headers: { 'Content-Type': file ? file.type : null },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.post(
                    `/closureinformation`,
                    { ...closureInformation, invoice: dataKey },
                    HEADERS()
                );
            } else {
                res = await api.post(
                    `/closureinformation`,
                    { ...closureInformation },
                    HEADERS()
                );
            }
            dispatch({
                type: CREATE_CLOSUREINFORMATION,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete ClosureInformation
    const deleteClosureInformation = async (closureInformationId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/closureInformation/${closureInformationId}`,
                HEADERS()
            );
            dispatch({
                type: DELETE_CLOSUREINFORMATION,
                payload: res.data.deletedId,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update ClosureInformation
    const updateClosureInformation = async (
        closureInformation,
        closureInformationId,
        file
    ) => {
        clearState();
        setLoading();

        try {
            let res;
            if (file) {
                const uploadConfig = await api.post(
                    '/uploads/image',
                    { type: file.type, fileName: file.name },
                    HEADERS()
                );

                await axios.put(uploadConfig.data.url, file, {
                    headers: { 'Content-Type': file ? file.type : null },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.put(
                    `/closureinformation/${closureInformationId}`,
                    { invoice: dataKey },
                    HEADERS()
                );
            } else {
                res = await api.put(
                    `/closureinformation/${closureInformationId}`,
                    { ...closureInformation },
                    HEADERS()
                );
            }
            dispatch({
                type: UPDATE_CLOSUREINFORMATION,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <ClosureInformationContext.Provider
            value={{
                loading: state.loading,
                closureInformations: state.closureInformations,
                closureInformation: state.closureInformation,
                error: state.error,
                count: state.count,
                getClosureInformations,
                createClosureInformation,
                getClosureInformation,
                deleteClosureInformation,
                updateClosureInformation,
                getClosureInformationByLead,
                setLoading,
                clearState,
                AdvancedResults,
                getClosureInformationsByStore,
            }}
        >
            {props.children}
        </ClosureInformationContext.Provider>
    );
};

export default ClosureInformationState;
