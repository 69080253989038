import React, { useReducer } from 'react';
import RecordingContext from './recordingContext';
import RecordingReducer from './recordingReducer';
import api from '../../api/api';
import {
    GET_RECORDINGS,
    CREATE_RECORDING,
    GET_RECORDING,
    DELETE_RECORDING,
    UPDATE_RECORDING,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const RecordingState = (props) => {
    const initialState = {
        recordings: [],
        recording: {},
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(RecordingReducer, initialState);

    const getRecordings = async (values) => {
        setLoading();
        try {
            let res = await api.post(
                `/recordings/recordingsAr`,
                { ...values },
                HEADERS()
            );

            dispatch({
                type: GET_RECORDINGS,
                payload: res.data.data,
                count: res?.data?.pagination?.total
                    ? res.data.pagination.total
                    : 0,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Recording
    const getRecording = async (recordingId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(`/recordings/${recordingId}`, HEADERS());
            dispatch({ type: GET_RECORDING, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Recording
    const deleteRecording = async (recordingId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/recordings/${recordingId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_RECORDING, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Recording
    const createRecording = async (recording) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/recordings`,
                { ...recording },
                HEADERS()
            );
            dispatch({ type: CREATE_RECORDING, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Recording
    const updateRecording = async (recording, recordingId) => {
        setLoading();
        try {
            const res = await api.put(
                `/recordings/${recordingId}`,
                { ...recording },
                HEADERS()
            );
            dispatch({ type: UPDATE_RECORDING, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <RecordingContext.Provider
            value={{
                loading: state.loading,
                recordings: state.recordings,
                recording: state.recording,
                error: state.error,
                count: state.count,
                getRecordings,
                createRecording,
                getRecording,
                deleteRecording,
                updateRecording,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </RecordingContext.Provider>
    );
};

export default RecordingState;
