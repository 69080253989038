import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon,
    Typography,
    Badge,
    useTheme,
} from '@material-ui/core';
import { Menu as MenuIcon, HelpCircle } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Settings from './Settings';
import useNotification from 'src/hooks/useNotification';
import Notification from './Notifications';
import Tickets from './Tickets';
import Tooltip from '@material-ui/core/Tooltip';
import TaskIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import useTicket from 'src/hooks/useTicket';
import ChatIcon from '@material-ui/icons/Chat';
import { ChatbotIcon } from 'src/components/ChatbotIcon';
import { Star } from '@material-ui/icons';

import {
    isAdmin,
    isRockstar,
    isSuper,
    isUser,
    isGeneralManager,
    isAdminBDC,
    isAdminService,
    isSalesManager,
    isCarone,
    isUserBDC,
    isUserService,
    isMarketing,
    isDigitalMkt,
} from 'src/utils/AuthRoles';
import { useTranslation } from 'react-i18next';
import { InternetSignal } from 'src/components/Alerts/InternetSignal';
import { isPluginAvailable } from 'src/utils/store/isPluginAvailable';
import { CHAT_BOT_PLUGIN } from 'src/constants/plugins';

const useStyles = makeStyles((theme) => ({
    favorite: {
        color: theme.palette.warning.main,
    },
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: 'none',
                  backgroundColor: theme.palette.primary.main,
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK || theme.name === THEMES.DARK_WARBOX
            ? {
                  backgroundColor: theme.palette.background.default,
              }
            : {}),
        ...(theme.name === THEMES.HALLOWEEN
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundSize: '15%',
                  backgroundImage:
                      'url("https://i.pinimg.com/736x/59/ec/b0/59ecb0f60a7b239b73c6fe2f0798291b.jpg")',
                  filter: 'grayscale(15%) drop-shadow(0px 0px 10px #F07D23) brightness(90%)',
                  color: 'white',
                  '-webkit-text-stroke': '0.25px #FFF',
              }
            : {}),
        ...(theme.name === THEMES.ORNN
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '500px 0px',
                  backgroundSize: '55%',
                  backgroundImage:
                      'url("https://i.ibb.co/kcnWCvH/thumb-1920-864277.jpg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.CATS
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -20px',
                  backgroundSize: '10%',
                  backgroundImage:
                      'url("https://data.whicdn.com/images/190747603/original.png")',
                  color: 'black',
              }
            : {}),
        ...(theme.name === THEMES.GWEN
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '350px -50px',
                  backgroundSize: '55%',
                  backgroundImage:
                      'url("https://i.ibb.co/s3d4Q6S/campeona-de-munecas-gwen-league-of-legends-lol-papel-pintado-3440x1440-15.jpg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.GWEN_CAFE_CUTIE
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '450px 0px',
                  backgroundSize: '55%',
                  backgroundImage:
                      'url("https://i.postimg.cc/R0W1wsXz/uhdpaper-com-201e-pc-8k-1.jpg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.SAN_VALENTIN
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -20px',
                  backgroundSize: '15%',
                  backgroundImage:
                      'url("https://image.freepik.com/vector-gratis/patron-fisuras-dia-san-valentin_96961-564.jpg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.SAN_VALENTIN_SAD
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -30px',
                  backgroundSize: '15%',
                  backgroundImage:
                      'url("https://images.freeimages.com/images/large-previews/a3d/broken-heart-brush-1637382.jpg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.NEW_YEAR
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -40px',
                  backgroundSize: '11%',
                  backgroundImage:
                      'url("https://www.bestoforlando.com/assets/upload/articles/best-orlando-views-watching-new-years-eve-fireworks/fireworks-450X270.jpg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.NOWAYHOME
            ? {
                  backgroundPositionY: '25%',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: '0px -40px',
                  backgroundImage:
                      'url("https://allears.net/wp-content/uploads/2021/06/22EC9E80-F2EE-44EF-95FA-850A9D181C31-1536x864.jpeg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.DEADS_DAY
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -150px',
                  backgroundSize: '12%',
                  backgroundImage:
                      'url("https://automotive-api.s3.us-east-2.amazonaws.com/5d7a514b5d2c12c7449be042/e945b57f-e721-4a6e-9ea0-b419240176b6/diademuertos.jpeg")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.NAVIDAD
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -150px',
                  backgroundSize: '12%',
                  backgroundImage:
                      'url("https://i.pinimg.com/736x/40/b5/79/40b579283c397cd40d8b35f9658c5812.jpg")',
                  color: 'black',
              }
            : {}),
        ...(theme.name === THEMES['NAVIDAD 2023']
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -30px',
                  backgroundSize: '13%',
                  backgroundImage:
                      'url("https://images.vexels.com/content/218577/preview/merry-christmas-pattern-design-be5780.png")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.GRINCH || theme.name === THEMES['GRINCH DARK']
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -30px',
                  backgroundSize: '13%',
                  backgroundImage:
                      'url("https://d2v8skpstyl8bm.cloudfront.net/products/images/product/4/2/42f808af-6d82-3b92-8105-541050e9ec66.jpg?1595522024")',
                  color: 'white',
              }
            : {}),
        ...(theme.name === THEMES.NAVIDARKS
            ? {
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '0px -150px',
                  backgroundSize: '12%',
                  backgroundImage:
                      'url("https://cdn2.vectorstock.com/i/1000x1000/74/56/black-christmas-snowy-background-vector-22617456.jpg")',
                  color: 'white',
              }
            : {}),
    },
    birthday: {
        backgroundRepeat: 'repeat',
        backgroundSize: '10%',
        backgroundImage:
            'url("https://i.pinimg.com/736x/67/98/8d/67988d494f5bd62945fcd88fff44d80d.jpg")',
        color: 'black',
    },
    autocomplete: {
        width: 600,
        backgroundColor:
            theme.palette.type === 'light' ? 'white' : theme.palette.divider,
        borderRadius: 5,
    },
    deadsDay: {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -150px',
        backgroundSize: '12%',
        backgroundImage:
            'url("https://automotive-api.s3.us-east-2.amazonaws.com/5d7a514b5d2c12c7449be042/e945b57f-e721-4a6e-9ea0-b419240176b6/diademuertos.jpeg")',
        color: 'white',
    },
    halloween: {
        backgroundRepeat: 'repeat',
        backgroundSize: '13%',
        backgroundImage:
            'url("https://image.freepik.com/free-vector/hand-drawn-halloween-pattern-with-coffins_52683-44439.jpg")',
        color: 'white',
    },
    toolbar: {
        height: 80,
    },
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
}));

const TopBar = ({
    className,
    onMobileNavOpen = () => {},
    canMarkAsFavorite,
    ...rest
}) => {
    const classes = useStyles();
    const { notifications } = useNotification();
    const { notificationTickets } = useTicket();
    const { user } = useAuth();
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();

    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('favorites')) {
            const routes = JSON.parse(localStorage.getItem('favorites'));
            if (routes.includes(history.location.pathname)) setFavorite(true);
            else setFavorite(false);
        }
        //eslint-disable-next-line
    }, [history.location]);

    const handleSaveFavoritePage = () => {
        let routes = JSON.parse(localStorage.getItem('favorites')) || [];

        if (!routes.includes(history.location.pathname)) {
            routes.push(history.location.pathname);

            localStorage.setItem('favorites', JSON.stringify(routes));
            setFavorite(true);
        } else {
            routes = routes.filter(
                (item) => item !== history.location.pathname
            );
            localStorage.setItem('favorites', JSON.stringify(routes));
            setFavorite(false);
        }
    };

    const storeHasChatBotAddon = () =>
        user.stores &&
        user.stores.length > 0 &&
        isPluginAvailable(user.stores, CHAT_BOT_PLUGIN);

    const tierAndStoreChatBotAddon = () =>
        storeHasChatBotAddon() &&
        (isAdminBDC(user.tier._id) ||
            isAdminService(user.tier._id) ||
            isAdmin(user.tier._id) ||
            isMarketing(user.tier._id) ||
            isDigitalMkt(user.tier._id) ||
            isGeneralManager(user.tier._id));

    return (
        <AppBar
            className={clsx({
                [classes.root]: true,
                [classes.deadsDay]: moment().format('DD MM') === '02 11',
                [classes.halloween]: moment().format('DD MM') === '31 10',
                [classes.birthday]:
                    user &&
                    user.birthday &&
                    moment(user.birthday).format('DD MMMM') ===
                        moment().format('DD MMMM'),
            })}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to="/">
                        <Logo />
                    </RouterLink>
                </Hidden>
                <Box flexGrow={1} />
                <InternetSignal />
                {user && user.tier && !isCarone(user.tier._id) && (
                    <Box flexGrow={1}>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            style={{
                                color:
                                    user &&
                                    user.birthday &&
                                    moment(user.birthday).format('DD MMMM') ===
                                        moment().format('DD MMMM')
                                        ? 'black'
                                        : 'white',
                            }}
                        >
                            {user &&
                            user.group &&
                            localStorage.getItem('language')
                                ? user.group.messageEs &&
                                  localStorage.getItem('language') === 'es'
                                    ? user.group.messageEs
                                    : user.group.messageEn &&
                                        localStorage.getItem('language') ===
                                            'en'
                                      ? user.group.messageEn
                                      : ''
                                : ''}
                        </Typography>
                    </Box>
                )}

                {user &&
                    user.tier &&
                    (isGeneralManager(user.tier._id) ||
                        isSalesManager(user.tier._id) ||
                        isRockstar(user.tier._id) ||
                        isAdminBDC(user.tier._id) ||
                        isAdminService(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUser(user.tier._id) ||
                        isSuper(user.tier._id) ||
                        isUserBDC(user.tier._id) ||
                        isUserService(user.tier._id)) && (
                        <Box ml={2}>
                            <Tooltip title="Go to Conversations" arrow>
                                <IconButton
                                    color="inherit"
                                    aria-label="go to conversations"
                                    component={RouterLink}
                                    to="/conversations"
                                >
                                    <ChatIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                {user &&
                    user.tier &&
                    (isRockstar(user.tier._id) ||
                        isSuper(user.tier._id) ||
                        tierAndStoreChatBotAddon()) && (
                        <Box ml={2}>
                            <Tooltip title="Go to Bot IA" arrow>
                                <IconButton
                                    color="inherit"
                                    aria-label="go to bot ia"
                                    component={RouterLink}
                                    to="/management/chatbot"
                                >
                                    <ChatbotIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                {user &&
                    user.tier &&
                    (isGeneralManager(user.tier._id) ||
                        isSalesManager(user.tier._id) ||
                        isRockstar(user.tier._id) ||
                        isAdminBDC(user.tier._id) ||
                        isAdminService(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUser(user.tier._id) ||
                        isSuper(user.tier._id)) && (
                        <Box ml={2}>
                            <Tooltip title={t('GoToTasks')} arrow>
                                <IconButton
                                    color="inherit"
                                    aria-label={t('GoToTasks')}
                                    component={RouterLink}
                                    to="/tasks"
                                >
                                    <TaskIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                {user &&
                    user.tier &&
                    (isGeneralManager(user.tier._id) ||
                        isSalesManager(user.tier._id) ||
                        isRockstar(user.tier._id) ||
                        isAdminBDC(user.tier._id) ||
                        isAdminService(user.tier._id) ||
                        isAdmin(user.tier._id) ||
                        isUser(user.tier._id) ||
                        isSuper(user.tier._id)) && (
                        <Box ml={2}>
                            <Tooltip title={t('GoToCalendar')} arrow>
                                <IconButton
                                    color="inherit"
                                    aria-label={t('GoToCalendar')}
                                    component={RouterLink}
                                    to="/calendar"
                                >
                                    <EventIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                <Box ml={2}>
                    <Tooltip title="Tickets" arrow>
                        <IconButton
                            color="inherit"
                            aria-label="tickets"
                            component="a"
                            href={process.env.REACT_APP_URL_TICKETS}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <HelpCircle />
                        </IconButton>
                    </Tooltip>
                </Box>

                {user && user.tier && !isCarone(user.tier._id) && (
                    <Box ml={2}>
                        <Badge
                            style={{ marginTop: 15 }}
                            overlap="rectangular"
                            badgeContent={
                                notifications && notifications.length > 0
                                    ? notifications.length
                                    : 0
                            }
                            max={99}
                            color="error"
                        >
                            <Notification style={{ marginTop: '-15px' }} />
                        </Badge>
                    </Box>
                )}

                {user && user.tier && !isCarone(user.tier._id) && (
                    <Box ml={2}>
                        <Settings />
                    </Box>
                )}
                {canMarkAsFavorite && (
                    <Tooltip title={t('Favorite')} arrow>
                        <IconButton onClick={handleSaveFavoritePage}>
                            <SvgIcon fontSize="small">
                                {favorite ? (
                                    <Star
                                        style={{
                                            color: theme.palette.warning.main,
                                            border: 'solid',
                                            stroke: 'black',
                                            strokeWidth: 1.5,
                                        }}
                                    />
                                ) : (
                                    <Star
                                        style={{
                                            color: '#eee',
                                            border: 'solid',
                                            stroke: 'black',
                                            strokeWidth: 1.5,
                                        }}
                                    />
                                )}
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                )}

                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
