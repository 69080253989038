import React, { useReducer } from 'react';
import ReminderContext from './reminderContext';
import ReminderReducer from './reminderReducer';
import api from '../../api/api';
import {
    GET_REMINDERS,
    CREATE_REMINDER,
    GET_REMINDER,
    DELETE_REMINDER,
    UPDATE_REMINDER,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const ReminderState = (props) => {
    const initialState = {
        reminders: [],
        reminder: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(ReminderReducer, initialState);

    //Get Reminders
    const getReminders = async () => {
        setLoading();
        try {
            const res = await api.get(`/reminders?isActive=true`, HEADERS());
            dispatch({ type: GET_REMINDERS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Reminders by User
    const getRemindersByUser = async (userId) => {
        setLoading();
        try {
            const res = await api.get(
                `users/${userId}/reminders?isActive=true`,
                HEADERS()
            );
            dispatch({ type: GET_REMINDERS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Reminder
    const getReminder = async (reminderId) => {
        clearReminderState();
        setLoading();
        try {
            const res = await api.get(`/reminders/${reminderId}`, HEADERS());
            dispatch({ type: GET_REMINDER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Reminder
    const deleteReminder = async (reminderId) => {
        setLoading();
        try {
            const res = await api.delete(`/reminders/${reminderId}`, HEADERS());
            dispatch({ type: DELETE_REMINDER, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Reminder
    const createReminder = async (reminder) => {
        setLoading();
        try {
            const res = await api.post(
                `/reminders`,
                { ...reminder },
                HEADERS()
            );
            dispatch({ type: CREATE_REMINDER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Reminder
    const updateReminder = async (reminder, reminderId) => {
        setLoading();
        try {
            const res = await api.put(
                `/reminders/${reminderId}`,
                { ...reminder },
                HEADERS()
            );
            dispatch({ type: UPDATE_REMINDER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearReminderState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <ReminderContext.Provider
            value={{
                loading: state.loading,
                reminders: state.reminders,
                reminder: state.reminder,
                error: state.error,
                getReminders,
                createReminder,
                getReminder,
                deleteReminder,
                updateReminder,
                clearReminderState,
                getRemindersByUser,
                setLoading,
            }}
        >
            {props.children}
        </ReminderContext.Provider>
    );
};

export default ReminderState;
