import { Dispatch } from 'react';
import api from '../../../api/api';
import { TMetaActionTypes, TMetaAdsValues } from '../types';
import { SET_LOADING } from 'src/contexts/types';
import { handleError } from 'src/contexts/utils/handleError';
import { GET_META_ADS_CAMPAIGNS } from '../constants/actions';
import { HEADERS } from 'src/constants/headers';

export const getMetaAdsCampaigns = async (
    dispatch: Dispatch<TMetaActionTypes>,
    values: TMetaAdsValues
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await api.post('/meta/ads/campaigns', values, HEADERS());
        dispatch({ type: GET_META_ADS_CAMPAIGNS, payload: res.data });
    } catch (error) {
        const err: any = error;
        dispatch(handleError(err));
    }
};
