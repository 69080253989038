import React, { useReducer } from 'react';
import MailContext from './mailContext';
import MailReducer from './mailReducer';
import api from '../../api/api';
import {
    CREATE_MAIL,
    CREATE_MAIL_ATTACHMENT,
    CLEAR_STATE,
    SET_LOADING,
    CLEAR_SNACKBARS,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';
import { handleError } from '../utils/handleError';

const MailState = (props) => {
    const initialState = {
        mails: [],
        mail: {},
        loading: false,
        error: null,
        success: null,
    };

    const [state, dispatch] = useReducer(MailReducer, initialState);

    //Create Mail
    const createMail = async (mail) => {
        setLoading();
        try {
            const res = await api.post(`/mails`, { ...mail }, HEADERS());
            dispatch({ type: CREATE_MAIL, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const updatePaymentMethod = async (mail) => {
        setLoading();
        try {
            const res = await api.post(
                `/mails/payments`,
                { ...mail },
                HEADERS()
            );
            dispatch({
                type: CREATE_MAIL,
                payload: res.data.data,
                successMessage: 'SnackBar.UpdatePayment',
            });
            setSnackbarInterval();
        } catch (error) {
            setSnackbarInterval();
            dispatch(handleError(error));
        }
    };

    const createMailAttachmentSemis = async (
        mail,
        attachment,
        documentNames
    ) => {
        try {
            setLoading();

            let uploadConfig;
            let dataKeys = [];
            for (let i = 0; i < attachment.length; i++) {
                uploadConfig = await api.post(
                    '/uploads/image',
                    { type: attachment[i].type, fileName: attachment[i].name },
                    HEADERS()
                );
                await axios.put(uploadConfig.data.url, attachment[i], {
                    headers: { 'Content-Type': attachment[i].type },
                });
                dataKeys.push(uploadConfig.data.key);
            }

            const res = await api.post(
                `/mails`,
                { ...mail, attachments: dataKeys, documentNames },
                HEADERS()
            );

            dispatch({ type: CREATE_MAIL_ATTACHMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Mail attachment
    const createMailAttachment = async (mail, attachment, documentNames) => {
        setLoading();
        try {
            const res = await api.post(
                `/mails`,
                { ...mail, attachments: attachment, documentNames },
                HEADERS()
            );
            dispatch({ type: CREATE_MAIL_ATTACHMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    const clearSnackbars = () =>
        dispatch({
            type: CLEAR_SNACKBARS,
        });

    const setSnackbarInterval = () => setTimeout(() => clearSnackbars(), 1000);

    return (
        <MailContext.Provider
            value={{
                ...state,
                createMail,
                clearState,
                setLoading,
                createMailAttachment,
                createMailAttachmentSemis,
                updatePaymentMethod,
            }}
        >
            {props.children}
        </MailContext.Provider>
    );
};

export default MailState;
