import React, { useReducer } from 'react';
import AppointmentContext from './appointmentContext';
import AppointmentReducer from './appointmentReducer';
import api from '../../api/api';
import {
    GET_APPOINTMENTS,
    CREATE_APPOINTMENT,
    GET_APPOINTMENT,
    DELETE_APPOINTMENT,
    UPDATE_APPOINTMENT,
    CLEAR_STATE,
    SET_LOADING,
    GET_APPOINTMENTS_BY_USER,
    GET_APPOINTMENTS_BY_STORE,
    GET_APPOINTMENTS_AR,
    CLEAR_SNACKBARS,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const AppointmentState = (props) => {
    const initialState = {
        appointments: [],
        appointment: {},
        loading: false,
        error: null,
        success: null,
    };

    const [state, dispatch] = useReducer(AppointmentReducer, initialState);

    //Get Appointments
    const getAppointments = async () => {
        setLoading();
        try {
            const res = await api.get(`/appointments?status=true`, HEADERS());

            dispatch({ type: GET_APPOINTMENTS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Appointments By User
    const getAppointmentsByUser = async (userId, query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(
                `/appointments/admin?status=true&user=${userId}${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_APPOINTMENTS_BY_USER,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Appointments By Store
    const getAppointmentsByStore = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/appointments/admin?status=true${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_APPOINTMENTS_BY_STORE,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Appointment
    const getAppointment = async (appointmentId) => {
        setLoading();
        try {
            const res = await api.get(
                `/appointments/${appointmentId}`,
                HEADERS()
            );
            dispatch({ type: GET_APPOINTMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Appointment
    const deleteAppointment = async (appointmentId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/appointments/${appointmentId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_APPOINTMENT, payload: res.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Appointment
    const createAppointment = async (appointment) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/appointments`,
                {
                    ...appointment,
                    endDate: appointment.allDay
                        ? appointment.startDate
                        : appointment.endDate,
                },
                HEADERS()
            );
            dispatch({
                type: CREATE_APPOINTMENT,
                payload: res.data.data,
                success: 'Appointment.Created',
            });
            setSnackbarInterval();
        } catch (error) {
            setSnackbarInterval();
            dispatch(handleError(error));
        }
    };

    //Create Appointment
    const createAppointmentLeadBDC = async (appointment) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/appointments/leadBDC`,
                {
                    ...appointment,
                    endDate: appointment.allDay
                        ? appointment.startDate
                        : appointment.endDate,
                },
                HEADERS()
            );
            dispatch({ type: CREATE_APPOINTMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Appointment
    const updateAppointment = async (appointment, appointmentId) => {
        setLoading();
        try {
            const res = await api.put(
                `/appointments/${appointmentId}`,
                { ...appointment },
                HEADERS()
            );
            dispatch({ type: UPDATE_APPOINTMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get appointments AdvancedResults
    const getAppointmentsAR = async (search) => {
        setLoading();
        try {
            const res = await api.get(`/appointments${search}`, HEADERS());
            dispatch({ type: GET_APPOINTMENTS_AR, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    const clearSnackbars = () =>
        dispatch({
            type: CLEAR_SNACKBARS,
        });

    const setSnackbarInterval = () => setTimeout(() => clearSnackbars(), 1000);

    return (
        <AppointmentContext.Provider
            value={{
                loading: state.loading,
                appointments: state.appointments,
                appointment: state.appointment,
                error: state.error,
                success: state.success,
                getAppointments,
                createAppointment,
                getAppointment,
                deleteAppointment,
                createAppointmentLeadBDC,
                updateAppointment,
                clearState,
                setLoading,
                getAppointmentsByUser,
                getAppointmentsByStore,
                getAppointmentsAR,
            }}
        >
            {props.children}
        </AppointmentContext.Provider>
    );
};

export default AppointmentState;
