import React, { useReducer } from 'react';
import StoreContext from './storeContext';
import StoreReducer from './storeReducer';
import api from '../../api/api';
import {
    GET_STORES,
    CREATE_STORE,
    GET_STORE,
    DELETE_STORE,
    UPDATE_STORE,
    CLEAR_STATE,
    SET_LOADING,
    UPDATE_ALL_STORES,
    UPDATE_ATTRIBUTES_STORES,
    CLEAR_SNACKBARS,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';
import { handleError } from '../utils/handleError';

const StoreState = (props) => {
    const initialState = {
        stores: [],
        allStores: [],
        store: {},
        count: null,
        loading: false,
        error: null,
        success: null,
    };

    const [state, dispatch] = useReducer(StoreReducer, initialState);

    const setCreditCount = async (storeId, data) => {
        setLoading();
        try {
            const res = await api.put(
                `/stores/credit/${storeId}`,
                { ...data },
                HEADERS()
            );
            dispatch({ type: UPDATE_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getStores = async (query = {}) => {
        clearStoreState();
        setLoading();
        try {
            const res = await api.post(
                `/stores/searchDocuments`,
                query,
                HEADERS()
            );
            dispatch({
                type: GET_STORES,
                payload: res.data.results,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const updateAllStores = async (data) =>
        dispatch({ type: UPDATE_ALL_STORES, payload: { ...data } });

    const updateAttStores = async (value) => {
        dispatch({ type: UPDATE_ATTRIBUTES_STORES, payload: value });
    };

    const getStoreByConversation = async (credentials) => {
        setLoading();
        try {
            const res = await api.get(
                `/stores/conversation/${credentials}`,
                HEADERS()
            );
            dispatch({ type: GET_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getStoreByConversationBDC = async (credentials) => {
        setLoading();
        try {
            const res = await api.get(
                `/stores/conversationBDC/${credentials}`,
                HEADERS()
            );
            dispatch({ type: GET_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getStore = async (storeId) => {
        setLoading();
        try {
            const res = await api.get(`/stores/${storeId}`, HEADERS());
            dispatch({ type: GET_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const createStore = async (store) => {
        clearStoreState();
        setLoading();
        try {
            const res = await api.post(`/stores`, { ...store }, HEADERS());
            dispatch({ type: CREATE_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const deleteStore = async (storeId) => {
        setLoading();
        try {
            const res = await api.delete(`/stores/${storeId}`, HEADERS());
            dispatch({ type: DELETE_STORE, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const updateStore = async (store, storeId, file) => {
        if (file) {
            let uploadConfig = await api.post(
                '/uploads/image',
                { type: file.type, fileName: file.name },
                HEADERS()
            );

            await axios.put(uploadConfig.data.url, file, {
                headers: { 'Content-Type': file ? file.type : null },
            });

            const dataKey = uploadConfig.data.key;

            if (store.hasOwnProperty('videoMailing')) {
                store.videoMailing.imageVideo = dataKey;
            } else {
                store.image = dataKey;
            }
        }
        setLoading();
        try {
            const res = await api.put(
                `/stores/${storeId}`,
                { ...store },
                HEADERS()
            );
            dispatch({
                type: UPDATE_STORE,
                payload: res.data.data,
                successMessage: 'SnackBar.StoreUpdated',
            });
            setSnackbarInterval();
        } catch (error) {
            setSnackbarInterval();
            dispatch(handleError(error));
        }
    };
    const updateCarouselAgents = async (store) => {
        setLoading();
        try {
            const res = await api.post(
                `/stores/updateCarousel `,
                { ...store },
                HEADERS()
            );
            dispatch({
                type: UPDATE_STORE,
                payload: res.data.data,
                successMessage: 'SnackBar.UpdateCarousel',
            });
            setSnackbarInterval();
        } catch (error) {
            setSnackbarInterval();
            dispatch(handleError(error));
        }
    };

    const clearSnackbars = () =>
        dispatch({
            type: CLEAR_SNACKBARS,
        });

    const setSnackbarInterval = () => setTimeout(() => clearSnackbars(), 1000);

    const clearStoreState = () => dispatch({ type: CLEAR_STATE });

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <StoreContext.Provider
            value={{
                ...state,
                getStore,
                createStore,
                deleteStore,
                updateStore,
                clearStoreState,
                setLoading,
                updateAllStores,
                getStoreByConversation,
                getStoreByConversationBDC,
                updateAttStores,
                getStores,
                setCreditCount,
                updateCarouselAgents,
            }}
        >
            {props.children}
        </StoreContext.Provider>
    );
};

export default StoreState;
