import React, { useReducer } from 'react';
import PackageContext from './packageContext';
import PackageReducer from './packageReducer';
import api from '../../api/api';
import {
    GET_PACKAGES,
    CREATE_PACKAGE,
    GET_PACKAGE,
    DELETE_PACKAGE,
    UPDATE_PACKAGE,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const PackageState = (props) => {
    const initialState = {
        packages: [],
        packageState: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(PackageReducer, initialState);

    const getPackagesByMake = async (query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(
                `/packages/multiMake?sort=name${query}`,
                HEADERS()
            );
            dispatch({ type: GET_PACKAGES, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Packages
    const getPackages = async (query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(`/packages?sort=name${query}`, HEADERS());
            dispatch({ type: GET_PACKAGES, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Packages
    const getPackagesService = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/packages/aggregationV3`,
                { ...values },
                HEADERS()
            );
            dispatch({ type: GET_PACKAGES, payload: res.data.results.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Packages
    const getPackagesV3 = async (body) => {
        setLoading();
        try {
            const res = await api.post(
                `/packages/AggregationV3`,
                body,
                HEADERS()
            );
            dispatch({ type: GET_PACKAGES, payload: res.data.results.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Package
    const getPackage = async (packageId) => {
        setLoading();
        try {
            const res = await api.get(`/packages/${packageId}`, HEADERS());
            dispatch({ type: GET_PACKAGE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Package
    const deletePackage = async (packageId) => {
        setLoading();
        try {
            const res = await api.delete(`/packages/${packageId}`, HEADERS());
            dispatch({ type: DELETE_PACKAGE, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Package
    const createPackage = async (packageProp) => {
        clearPackageState();
        setLoading();
        try {
            const res = await api.post(
                `/packages`,
                { ...packageProp },
                HEADERS()
            );
            dispatch({ type: CREATE_PACKAGE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Package
    const updatePackage = async (packageProp, packageId) => {
        setLoading();
        try {
            const res = await api.put(
                `/packages/${packageId}`,
                { ...packageProp },
                HEADERS()
            );
            dispatch({ type: UPDATE_PACKAGE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearPackageState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <PackageContext.Provider
            value={{
                loading: state.loading,
                packages: state.packages,
                packageState: state.packageState,
                error: state.error,
                getPackages,
                getPackagesV3,
                getPackagesService,
                createPackage,
                getPackage,
                deletePackage,
                updatePackage,
                clearPackageState,
                setLoading,
                getPackagesByMake,
            }}
        >
            {props.children}
        </PackageContext.Provider>
    );
};

export default PackageState;
