import React from 'react';
import { IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
    TPayload,
    TTicketPayload,
    TNotificationOptions,
} from 'src/components/Alerts/types';
import { ExternalLink } from 'react-feather';
import { useHistory } from 'react-router';
import { getSnackbarProps } from '../utils/getSnackbarProps';
import { useInteraction } from 'src/hooks/useInteraction';

export const SnackbarAlert = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const hasUserInteracted = useInteraction();

    const playNotificationSound = () => {
        if (hasUserInteracted) {
            const audio = new Audio('/static/notification.mp3');
            audio.play();
        }
    };

    const actionButton = (url: string) => (
        <IconButton onClick={() => history.push(url)}>
            <ExternalLink />
        </IconButton>
    );

    const conversationsAlert = (
        payload: TPayload,
        options?: TNotificationOptions
    ) => {
        const { conversationId, area, provider } = payload;
        const title = options?.title;

        playNotificationSound();

        enqueueSnackbar(title, {
            ...getSnackbarProps(options),
            action: actionButton(
                `/conversations?id=${conversationId}&area=${area}&provider=${provider}`
            ),
        });
    };

    const ticketAlert = (
        payload: TTicketPayload,
        options?: TNotificationOptions
    ) => {
        const { ticketId } = payload;
        const title = options?.title;

        playNotificationSound();

        enqueueSnackbar(title, {
            ...getSnackbarProps(options),
            action: actionButton(`/management/tickets/${ticketId}`),
        });
    };

    return {
        conversationsAlert,
        ticketAlert,
    };
};
