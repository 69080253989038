import { Dispatch } from 'react';
import { TEvaluationActionTypes } from '../types';
import { SET_LOADING } from 'src/contexts/types';
import { HEADERS } from 'src/constants/headers';
import { ApiV2 } from 'src/api/apiv2';
import { handleError } from 'src/contexts/utils/handleError';
import { IEvaluation } from 'src/interfaces/evaluation';
import { UPDATE_EVALUATION } from '../constants/actions';

/**
 * Will update an evaluation based on identification with the given options.
 *
 * @param {Dispatch<TEvaluationActionTypes>} dispatch
 * @param {string} evaluationId
 * @param {Partial<IEvaluation>} evaluation
 */
export const updateEvaluation = async (
    dispatch: Dispatch<TEvaluationActionTypes>,
    evaluationId: string,
    evaluation: Partial<IEvaluation>
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await ApiV2.put(
            `/evaluations/${evaluationId}`,
            { ...evaluation },
            HEADERS()
        );
        dispatch({ type: UPDATE_EVALUATION, payload: res.data.data });
    } catch (error) {
        const err: any = error;
        dispatch(handleError(err));
    }
};
