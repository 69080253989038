import React, { useReducer } from 'react';
import CommentContext from './commentContext';
import CommentReducer from './commentReducer';
import api from '../../api/api';
import {
    CREATE_COMMENT,
    CLEAR_STATE,
    GET_COMMENTS,
    GET_COMMENTS_BY_USER,
    GET_COMMENTS_BY_STORE,
    SET_LOADING,
    GET_COMMENT,
    DELETE_COMMENT,
    UPDATE_COMMENT,
    CLEAR_COMMENT,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const CommentState = (props) => {
    const initialState = {
        comments: [],
        comment: {},
        loading: false,
        error: null,
        count: 0,
    };

    const [state, dispatch] = useReducer(CommentReducer, initialState);

    //Create Comment
    const createComment = async (comment, leadId) => {
        try {
            const res = await api.post(
                `/leads/${leadId}/comments`,
                { ...comment },
                HEADERS()
            );
            dispatch({ type: CREATE_COMMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Comment
    const createCommentLeadBDC = async (comment, leadBDCId) => {
        try {
            const res = await api.post(
                `/comments/leadsBDC/${leadBDCId}`,
                { ...comment },
                HEADERS()
            );
            dispatch({ type: CREATE_COMMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Commment
    const updateComment = async (comment, commentId) => {
        setLoading();
        try {
            const res = await api.put(
                `/comments/${commentId}`,
                { ...comment },
                HEADERS()
            );
            dispatch({ type: UPDATE_COMMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Gt comments to ListView
    const getCommentsAR = async (data, queryData) => {
        setLoading();
        try {
            let res;
            let query = '';
            let reqQuery = { ...data };
            const removeFields = ['role'];

            removeFields.forEach((param) => delete reqQuery[param]);

            let keys = [];
            for (var key in reqQuery) {
                keys.push(key);
            }

            keys.map((item) => (query += `&${item}=${data[item]}`));

            if (data.role === 'rockstar') {
                res = await api.get(
                    `/comments/commentsAr?${query}${queryData}&searchIndex=name-store-make-agent-vehicle`,
                    HEADERS()
                );
            } else {
                res = await api.get(
                    `/comments/commentsAr?${query}${queryData}&searchIndex=name-store-make-agent-vehicle`,
                    HEADERS()
                );
            }

            dispatch({
                type: GET_COMMENTS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comments
    const getComments = async (values) => {
        setLoading();
        try {
            const res = await api.post(
                `/comments/AdvancedResults`,
                { ...values },
                HEADERS()
            );
            dispatch({
                type: GET_COMMENTS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comment
    const getComment = async (commentId) => {
        setLoading();
        try {
            const res = await api.get(`/comments/${commentId}`, HEADERS());
            dispatch({ type: GET_COMMENT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comments By User
    const getCommentsByUser = async (userId, query) => {
        setLoading();
        try {
            const res = await api.get(
                `comments?user=${userId}&pending=true${query}`,
                HEADERS()
            );
            dispatch({ type: GET_COMMENTS_BY_USER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comments By User
    const getCommentsByUserLeadsBDC = async (userId, query) => {
        setLoading();
        try {
            const res = await api.get(
                `comments/adminLeadsBDC?user=${userId}&pending=true${query}`,
                HEADERS()
            );
            dispatch({ type: GET_COMMENTS_BY_USER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comments By Store
    const getCommentsByStore = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/comments/admin?&pending=true${query}`,
                HEADERS()
            );
            dispatch({ type: GET_COMMENTS_BY_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comments By Store
    const getCommentsByStoreLeadsBDC = async (query) => {
        setLoading();
        try {
            const res = await api.get(
                `/comments/adminLeadsBDC?&pending=true${query}`,
                HEADERS()
            );
            dispatch({ type: GET_COMMENTS_BY_STORE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Comment
    const deleteComment = async (commentId) => {
        clearState();
        setLoading();
        try {
            const res = await api.delete(`/comments/${commentId}`, HEADERS());
            dispatch({ type: DELETE_COMMENT, payload: res.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    const clearComment = () => dispatch({ type: CLEAR_COMMENT });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <CommentContext.Provider
            value={{
                loading: state.loading,
                comments: state.comments,
                comment: state.comment,
                error: state.error,
                count: state.count,
                getCommentsByStore,
                getCommentsByUser,
                getComments,
                getCommentsByStoreLeadsBDC,
                getCommentsByUserLeadsBDC,
                createComment,
                clearState,
                getComment,
                deleteComment,
                createCommentLeadBDC,
                updateComment,
                getCommentsAR,
                clearComment,
            }}
        >
            {props.children}
        </CommentContext.Provider>
    );
};

export default CommentState;
