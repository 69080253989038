import { Dispatch } from 'react';
import { IEvaluation } from 'src/interfaces/evaluation';
import { TEvaluationActionTypes } from '../types';
import { HEADERS } from 'src/constants/headers';
import { ApiV2 } from 'src/api/apiv2';
import { SET_LOADING } from 'src/contexts/types';
import { handleError } from 'src/contexts/utils/handleError';
import { GET_EVALUATIONS } from '../constants/actions';

/**
 *
 *  Will return a series of evaluations with given search parameters. *
 * @param {Dispatch<TEvaluationActionTypes>} dispatch
 * @param {Partial<IEvaluation>} values
 */
export const getEvaluations = async (
    dispatch: Dispatch<TEvaluationActionTypes>,
    values: Partial<IEvaluation>
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await ApiV2.post(`/evaluations/list`, values, HEADERS());

        dispatch({
            type: GET_EVALUATIONS,
            payload: res.data.results,
            total: res.data.total,
        });
    } catch (error) {
        const err: any = error;
        dispatch(handleError(err));
    }
};
