import React, { useReducer } from 'react';
import { MetaContext } from './metaContext';
import MetaReducer from './metaReducer';
import { DEFAULT_META_STATE } from './constants/defaultState';
import {
    getMetaAdsCampaigns,
    getMetaAdsAdsets,
    getMetaAds,
    getMetaAdsInsights,
} from './utils';
import { clearState, setLoading } from '../utils';
import { IMetaState, TMetaAdsValues } from './types';

export const MetaState = ({ children }: IMetaState) => {
    const [state, dispatch] = useReducer(MetaReducer, DEFAULT_META_STATE);

    return (
        <MetaContext.Provider
            value={{
                ...state,
                getMetaAdsCampaigns: (values: TMetaAdsValues) =>
                    getMetaAdsCampaigns(dispatch, values),
                getMetaAdsAdsets: (values: TMetaAdsValues) =>
                    getMetaAdsAdsets(dispatch, values),
                getMetaAds: (values: TMetaAdsValues) =>
                    getMetaAds(dispatch, values),
                getMetaAdsInsights: (values: TMetaAdsValues) =>
                    getMetaAdsInsights(dispatch, values),
                clearState: () => clearState(dispatch, DEFAULT_META_STATE),
                setLoading: () => setLoading(dispatch),
            }}
        >
            {children}
        </MetaContext.Provider>
    );
};
