import { useState, useEffect } from 'react';

export const useInteraction = () => {
    const [hasInteracted, setHasInteracted] = useState(
        () => sessionStorage.getItem('userInteracted') === 'true'
    );

    useEffect(() => {
        const handleInteraction = () => {
            sessionStorage.setItem('userInteracted', 'true');
            setHasInteracted(true);
        };

        document.addEventListener('click', handleInteraction, { once: true });
        document.addEventListener('keydown', handleInteraction, { once: true });

        return () => {
            document.removeEventListener('click', handleInteraction);
            document.removeEventListener('keydown', handleInteraction);
        };
    }, []);

    return hasInteracted;
};
