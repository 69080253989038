import React, { useReducer } from 'react';
import SourceContext from './sourceContext';
import SourceReducer from './sourceReducer';
import api from '../../api/api';
import {
    GET_SOURCES,
    CREATE_SOURCE,
    GET_SOURCE,
    DELETE_SOURCE,
    UPDATE_SOURCE,
    CLEAR_STATE,
    SET_LOADING,
    DELETE_SOURCES,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { ApiV2 } from 'src/api/apiv2';
import { handleError } from '../utils/handleError';

const SourceState = (props) => {
    const initialState = {
        sources: [],
        source: {},
        total: 0,
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(SourceReducer, initialState);

    const getSourcesV2 = async (values) => {
        setLoading();
        try {
            const res = await ApiV2.post(`/sources/list`, values, HEADERS());
            dispatch({
                type: GET_SOURCES,
                payload: res.data.results,
                total: res.data.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const deleteSources = async (values, filters) => {
        setLoading();
        try {
            await ApiV2.post(`/sources/bulk-delete`, values, HEADERS());
            dispatch({
                type: DELETE_SOURCES,
            });

            if (filters) getSourcesV2(filters);
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Sources
    const getSources = async (query) => {
        if (!query) query = '';
        try {
            const res = await api.get(`/sources?sort=name${query}`, HEADERS());
            dispatch({ type: GET_SOURCES, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Source
    const getSource = async (sourceId) => {
        setLoading();
        try {
            const res = await api.get(`/sources/${sourceId}`, HEADERS());
            dispatch({ type: GET_SOURCE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Source
    const deleteSource = async (sourceId) => {
        setLoading();
        try {
            const res = await api.delete(`/sources/${sourceId}`, HEADERS());
            dispatch({ type: DELETE_SOURCE, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Source
    const createSource = async (source) => {
        clearSourceState();
        setLoading();
        try {
            const res = await api.post(`/sources`, { ...source }, HEADERS());
            dispatch({ type: CREATE_SOURCE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Source
    const updateSource = async (source, sourceId) => {
        setLoading();
        try {
            const res = await api.put(
                `/sources/${sourceId}`,
                { ...source },
                HEADERS()
            );
            dispatch({ type: UPDATE_SOURCE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearSourceState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <SourceContext.Provider
            value={{
                loading: state.loading,
                sources: state.sources,
                source: state.source,
                error: state.error,
                total: state.total,
                getSources,
                createSource,
                getSource,
                deleteSource,
                updateSource,
                clearSourceState,
                setLoading,
                getSourcesV2,
                deleteSources,
            }}
        >
            {props.children}
        </SourceContext.Provider>
    );
};

export default SourceState;
