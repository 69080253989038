import React, { useReducer } from 'react';
import VisitContext from './visitContext';
import VisitReducer from './visitReducer';
import api from '../../api/api';
import {
    GET_VISITS,
    CREATE_VISIT,
    GET_VISIT,
    DELETE_VISIT,
    UPDATE_VISIT,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const VisitState = (props) => {
    const initialState = {
        visits: [],
        visit: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(VisitReducer, initialState);

    //Get Visits
    const getVisits = async (all) => {
        setLoading();
        try {
            let actives = '';
            if (!all) {
                actives = '&isActive=true';
            }
            const res = await api.get(`/visits?sort=name${actives}`, HEADERS());
            dispatch({ type: GET_VISITS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Visit
    const getVisit = async (visitId) => {
        clearVisitState();
        setLoading();
        try {
            const res = await api.get(`/visits/${visitId}`, HEADERS());
            dispatch({ type: GET_VISIT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Visit
    const deleteVisit = async (visitId) => {
        setLoading();
        try {
            const res = await api.delete(`/visits/${visitId}`, HEADERS());
            dispatch({ type: DELETE_VISIT, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Visit
    const createVisit = async (visit) => {
        clearVisitState();
        setLoading();
        try {
            const res = await api.post(`/visits`, { ...visit }, HEADERS());
            dispatch({ type: CREATE_VISIT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Visit
    const updateVisit = async (visit, visitId) => {
        setLoading();
        try {
            const res = await api.put(
                `/visits/${visitId}`,
                { ...visit },
                HEADERS()
            );
            dispatch({ type: UPDATE_VISIT, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearVisitState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <VisitContext.Provider
            value={{
                loading: state.loading,
                visits: state.visits,
                visit: state.visit,
                error: state.error,
                getVisits,
                createVisit,
                getVisit,
                deleteVisit,
                updateVisit,
                clearVisitState,
                setLoading,
            }}
        >
            {props.children}
        </VisitContext.Provider>
    );
};

export default VisitState;
