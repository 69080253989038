import { AxiosError } from 'axios';
import { ISetError, SET_ERROR } from '../types';
import { UNKNOWN_ERROR_MESSAGE } from './constants';

/**
 * Will handle state's errors in order to be from axios or error
 *
 * @param {*} error
 * @return {*}  {ISetError}
 */
export const handleError = (error: AxiosError | Error): ISetError => {
    if ('response' in error) {
        return { type: SET_ERROR, payload: error.response?.data };
    } else if (error instanceof Error) {
        return { type: SET_ERROR, payload: error.message };
    } else {
        return { type: SET_ERROR, payload: UNKNOWN_ERROR_MESSAGE };
    }
};
