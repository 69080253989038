import { Dispatch } from 'react';
import api from '../../../api/api';
import { TMetaActionTypes, TMetaAdsValues } from '../types';
import { SET_LOADING } from 'src/contexts/types';
import { handleError } from 'src/contexts/utils/handleError';
import { GET_META_ADS_INSIGHTS } from '../constants/actions';
import { HEADERS } from 'src/constants/headers';

export const getMetaAdsInsights = async (
    dispatch: Dispatch<TMetaActionTypes>,
    values: TMetaAdsValues
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await api.post('/meta/ads/insights', values, HEADERS());
        dispatch({ type: GET_META_ADS_INSIGHTS, payload: res.data });
    } catch (error) {
        const err: any = error;
        dispatch(handleError(err));
    }
};
