export const DEFAULT_CHART_STATE = {
    count: 0,
    addonsReport: [],
    captacionMedios: [],
    globalSources: [],
    global: [],
    temperaturesChart: [],
    drilldownTime: [],
    reportSales: [],
    funnelDatos: [],
    monitoringAgent: [],
    monitoringAgentSources: {},
    tableSources: [],
    tableAgentSources: [],
    leadsStoreChart: {
        bar: { series: [{ data: [], name: '' }] },
        categories: [],
        colors: [],
    },
    appointmentsByStore: {
        data: [],
        categories: [],
        colors: [],
    },
    visitsByStore: {
        data: [],
        categories: [],
        colors: [],
    },
    soldsByStore: {
        data: [],
        categories: [],
        colors: [],
    },
    profitChart: {
        bar: {
            series: [{ name: '', data: [] }],
            totals: [],
        },
        categories: [],
    },
    operationTypeChart: {
        categories: [],
        series: [],
    },
    financialInstitutionChart: {
        bar: {
            series: [{ name: '', data: [] }],
        },
        categories: [],
    },
    appointmentsStoresChart: {
        series: [{ data: [], group: '', name: '' }],
        categories: [],
        colors: [],
    },
    appointmentsDetailsChart: {
        bar: {
            series: [{ data: [], name: '' }],
            totals: [],
        },
        categories: [],
    },
    profitStoreChart: {
        bar: {
            series: [{ name: '', data: [] }],
        },
        categories: [],
        colors: [],
    },
    profitModelChart: {
        bar: {
            series: [{ name: '', data: [] }],
            totals: [],
        },
        categories: [],
    },
    appointmentsSubstatusChart: {
        series: [{ data: [], name: '' }],
        categories: [],
    },
    visitsSubstatusChart: {
        series: [{ data: [], name: '' }],
        categories: [],
    },
    visitsStoresChart: {
        series: [{ data: [], name: '' }],
        categories: [],
        colors: [],
    },
    visitsDetailsChart: {
        bar: {
            series: [{ data: [], name: '' }],
            totals: [],
        },
        categories: [],
    },
    tableAgencies: [],
    drilldownCalls: [],
    drilldownPendingLeads: [],
    drilldownBureau: [],
    drilldownRecordings: [],
    drilldownFunnelSource: [],
    daily: [],
    models: [],
    hours: [],
    matrixInvestment: [],
    leadsMonthlyChart: {
        bar: {
            series: [{ data: [], name: '' }],
        },
        categories: [],
    },
    pieStatus: { categories: [], series: [] },
    substatusAgentChart: {
        categories: [],
        series: [{ data: [], name: '' }],
    },
    temperaturesLeadsTable: [],
    temperaturesAppointmentsTable: [],
    temperaturesVisitsTable: [],
    temperaturesSalesTable: [],
    agentsLeadsTable: [],
    agentsComparative: [],
    agentsCallsTable: [],
    storesExtraScoresTable: [],
    storesScoresTable: [],
    total: null,
    totalLeads: null,
    totalVisits: null,
    totalAppointments: null,
    totalSolds: null,
    closureTopUsers: {
        series: [{ data: [], name: '' }],
        categories: [],
    },
    agentsCallsChart: {
        series: [{ data: [], name: '' }],
        categories: [],
    },
    totalTemperatures: [],
    agentsVisitsTable: [],
    agentsAppointmentsTable: [],
    agentsSoldsTable: [],
    agentsStatusChart: [],
    modelsByMakeChart: {},
    monthlyAdminChart: [],
    unitMonthlyComparativeChart: [],
    multipleComparativeChart: [],
    vsChart: [],
    temperaturesFunnel: [],
    temperaturesFunnelRSI: [],
    temperaturesFunnelRSIComparative: [[], []],
    funnelAppointment: [],
    statusStoresChart: [],
    statusStoresChartAcumulated: [],
    temperaturesFunnelAppointmentComparative: [[], []],
    statusReportChart: [],
    appointmentsChart: [],
    hoursComparativeChart: [],
    visitsChart: [],
    totalProfits: null,
    totalFinancing: null,
    weeklyProfitChart: [],
    investmentProfitStoreChart: null,
    totalNew: null,
    totalFollowUp: null,
    totalCallAgain: null,
    totalWrongnumber: null,
    totalRejected: null,
    totalDocumentation: null,
    totalConfirm: null,
    totalConfirmed: null,
    totalVisitTracking: null,
    totalReschedule: null,
    totalClientNa: null,
    totalVisited: null,
    totalApplication: null,
    totalApprovedApplication: null,
    totalCondicionedApplication: null,
    totalRejectedApplication: null,
    totalSeparation: null,
    totalInvestment: null,
    totalProfitsInvestment: null,
    totalAppointmentsDash: null,
    totalVisitsDash: null,
    totalCallsDash: null,
    recordingsReviews: [],
    pendingTasksReport: [],
    bureauChart: [],
    tableModels: [],
    tableLeadsByAgents: [],
    matrix: {
        clicks: 0,
        impressions: 0,
        cpm: 0,
        leads: 0,
        spend: 0,
        costPerLead: 0,
    },
    matrixCampaigns: [],
    matrixAdsets: [],
    matrixSegmentation: {},
    matrixInvestmentCampaign: [],
    matrixInvestmentAdset: [],
    investments: [],
    investment: {},
    saleTimeChart: [],
    loadingCharts: false,
    error: null,
};
