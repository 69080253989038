import React, { useReducer } from 'react';
import CompanyContext from './companyContext';
import CompanyReducer from './companyReducer';
import api from '../../api/api';
import {
    GET_COMPANIES,
    CREATE_COMPANY,
    GET_COMPANY,
    DELETE_COMPANY,
    UPDATE_COMPANY,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const CompanyState = (props) => {
    const initialState = {
        companies: [],
        company: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(CompanyReducer, initialState);

    //Get Companies
    const getCompanies = async (query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(
                `/companies?sort=name${query}`,
                HEADERS()
            );
            dispatch({ type: GET_COMPANIES, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Company
    const getCompany = async (companyId) => {
        setLoading();
        try {
            const res = await api.get(`/companies/${companyId}`, HEADERS());
            dispatch({ type: GET_COMPANY, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Company
    const deleteCompany = async (companyId) => {
        setLoading();
        try {
            const res = await api.delete(`/companies/${companyId}`, HEADERS());
            dispatch({ type: DELETE_COMPANY, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Company
    const createCompany = async (company) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(`/companies`, { ...company }, HEADERS());
            dispatch({ type: CREATE_COMPANY, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Company
    const updateCompany = async (company, companyId) => {
        clearState();
        setLoading();
        try {
            const res = await api.put(
                `/companies/${companyId}`,
                { ...company },
                HEADERS()
            );
            dispatch({ type: UPDATE_COMPANY, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <CompanyContext.Provider
            value={{
                loading: state.loading,
                companies: state.companies,
                company: state.company,
                error: state.error,
                getCompanies,
                createCompany,
                getCompany,
                deleteCompany,
                updateCompany,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </CompanyContext.Provider>
    );
};

export default CompanyState;
