import { Dispatch } from 'react';
import { ApiV2 } from 'src/api/apiv2';
import { HEADERS } from 'src/constants/headers';
import { SET_LOADING } from 'src/contexts/types';
import { handleError } from 'src/contexts/utils/handleError';
import { TEvaluationActionTypes } from '../types';
import { GET_EVALUATION } from '../constants/actions';

/**
 * Will return a single evaluation with the given _id
 *
 * @param {Dispatch<TEvaluationActionTypes>} dispatch
 * @param {string} evaluationId
 */
export const getEvaluation = async (
    dispatch: Dispatch<TEvaluationActionTypes>,
    evaluationId: string
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await ApiV2.get(`/evaluations/${evaluationId}`, HEADERS());
        dispatch({ type: GET_EVALUATION, payload: res.data.data });
    } catch (error) {
        const err: any = error;
        dispatch(handleError(err));
    }
};
