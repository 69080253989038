import React, { useReducer } from 'react';
import VideoCallsContext from './videoCallContext';
import VideoCallReducer from './videoCallReducer';
import api from '../../api/api';
import { SET_LOADING, CLEAR_STATE, SET_VIDEOCALL } from '../types';
import { handleError } from '../utils/handleError';

const VideoCallState = (props) => {
    const initialState = {
        videoCall: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(VideoCallReducer, initialState);

    //End video call
    const updateVideoCall = async (values) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };

        try {
            setLoading();
            await api.post(`/videocalls/room/update`, { ...values }, config);
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get token videocall
    const createRoom = async (values) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };

        try {
            setLoading();

            const res = await api.post(
                '/videocalls/room/create',
                { ...values },
                config
            );
            dispatch({ type: SET_VIDEOCALL, payload: res.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Join Room
    const joinRoom = async (values) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };

        try {
            setLoading();

            const res = await api.post(
                '/videocalls/room/join',
                { ...values },
                config
            );
            dispatch({ type: SET_VIDEOCALL, payload: res.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear the state
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set loading true
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <VideoCallsContext.Provider
            value={{
                error: state.error,
                videoCall: state.videoCall,
                loadingVideoCalls: state.loading,
                clearVideoCalls: clearState,
                setLoading,
                createRoom,
                joinRoom,
                updateVideoCall,
            }}
        >
            {props.children}
        </VideoCallsContext.Provider>
    );
};

export default VideoCallState;
