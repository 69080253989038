import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

import {
    ADMIN_STRING,
    DIGITAL_MARKETING_STRING,
    GENERAL_MANAGER_STRING,
    ROCKSTAR_STRING,
    SUPER_ADMIN_STRING,
} from 'src/constants/role';

const META_ADS_VALID_USER_ROLES = [
    ROCKSTAR_STRING,
    ADMIN_STRING,
    SUPER_ADMIN_STRING,
    DIGITAL_MARKETING_STRING,
    GENERAL_MANAGER_STRING,
];

export const META_ADS_ROUTE = '/management/meta-ads';

export const MetaAdsRoutes = [
    {
        exact: true,
        path: META_ADS_ROUTE,
        guard: RolesGuard,
        roles: META_ADS_VALID_USER_ROLES,
        component: lazy(() => import('src/views/metaAds')),
    },
];
