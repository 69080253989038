import { clearState, setLoading } from 'src/contexts/utils';
import { IMetaDefaultContext } from '../types';
import * as MetaFunctions from '../utils';
import { DEFAULT_META_STATE } from './defaultState';

export const DEFAULT_META_CONTEXT: IMetaDefaultContext = {
    ...DEFAULT_META_STATE,
    ...MetaFunctions,
    clearState,
    setLoading,
};
