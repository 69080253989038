import React, { useReducer } from 'react';
import TicketContext from './ticketContext';
import TicketReducer from './ticketReducer';
import api from '../../api/api';
import {
    GET_TICKETS,
    CREATE_TICKET,
    GET_TICKET,
    DELETE_TICKET,
    UPDATE_TICKET,
    CLEAR_STATE,
    SET_LOADING,
    GET_NOTIFICATIONS_TICKETS,
    UPDATE_TICKET_LIST,
    UPDATE_LIST,
    DELETE_TICKETS,
    CLEAR_SNACKBARS,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { ApiV2 } from 'src/api/apiv2';
import { AWS_S3 } from 'src/constants/crm/aws';
import axios from 'axios';
import { handleError } from '../utils/handleError';

const TicketState = (props) => {
    const initialState = {
        tickets: [],
        ticket: {},
        notificationTickets: [],
        loading: false,
        error: null,
        success: null,
        count: null,
        total: 0,
    };

    const [state, dispatch] = useReducer(TicketReducer, initialState);

    const getTicketsV2 = async (values) => {
        setLoading();
        try {
            const res = await ApiV2.post(`/tickets/list`, values, HEADERS());
            dispatch({
                type: GET_TICKETS,
                payload: res.data.results,
                total: res.data.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const deleteTickets = async (values, filters) => {
        setLoading();
        try {
            await ApiV2.post(`/tickets/bulk-delete`, values, HEADERS());
            dispatch({
                type: DELETE_TICKETS,
            });

            if (filters) getTicketsV2(filters);
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const updateNotificationTickets = async (notificationId) =>
        dispatch({ type: UPDATE_LIST, payload: notificationId });

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/tickets?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and`,
                HEADERS()
            );
            dispatch({
                type: GET_TICKETS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const updateList = async (ticket) =>
        dispatch({ type: UPDATE_TICKET_LIST, payload: ticket });

    const getTicketsRockstar = async (isRead) => {
        setLoading();

        try {
            const res = await api.post(
                `/tickets/rockstar`,
                { isRead },
                HEADERS()
            );
            dispatch({
                type: GET_NOTIFICATIONS_TICKETS,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getTickets = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                res = await api.get(
                    `/tickets?page=${pagination.page}&limit=${pagination.limit}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_TICKETS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getTicketsByUser = async (userId, pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                res = await api.get(
                    `/tickets?page=${pagination.page}&limit=${pagination.limit}&user=${userId}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_TICKETS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getTicketsByGroup = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';
            if (pagination) {
                res = await api.get(
                    `/tickets/ticketsByGroup?page=${pagination.page}&limit=${pagination.limit}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_TICKETS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getTicketsByStore = async (stores, pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                res = await api.get(
                    `/tickets?page=${pagination.page}&limit=${pagination.limit}${stores}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_TICKETS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Ticket
    const getTicket = async (ticketId) => {
        clearTicketState();
        setLoading();
        try {
            const res = await api.get(`/tickets/${ticketId}`, HEADERS());
            dispatch({ type: GET_TICKET, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Ticket
    const deleteTicket = async (ticketId) => {
        setLoading();
        try {
            const res = await api.delete(`/tickets/${ticketId}`, HEADERS());
            dispatch({ type: DELETE_TICKET, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Ticket
    const createTicket = async (ticket) => {
        clearTicketState();
        setLoading();
        try {
            const { file } = ticket;
            if (file) {
                const { type, name: fileName } = file;

                const uploadConfig = await api.post(
                    '/uploads/image',
                    { type, fileName },
                    HEADERS()
                );

                const { data } = uploadConfig;
                const { url, key } = data;
                await axios.put(url, file, {
                    headers: {
                        'Content-Type': file?.type || null,
                    },
                });

                ticket.image = `${AWS_S3}${key}`;
            }

            const res = await ApiV2.post(
                `/tickets/create`,
                { ...ticket },
                HEADERS()
            );
            dispatch({ type: CREATE_TICKET, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Ticket
    const updateTicket = async (ticket, ticketId) => {
        setLoading();
        try {
            const length = ticket.media?.length || 0;

            let mediaAuxiliar = [];
            for (let i = 0; i < length; i++) {
                const currentMedia = ticket.media[i];
                const { type, name: fileName } = currentMedia;

                const response = await api.post(
                    '/uploads/image',
                    {
                        type,
                        fileName,
                    },
                    HEADERS()
                );
                const { data } = response;
                const { url, key } = data;

                await axios.put(url, currentMedia, {
                    headers: { 'Content-Type': type },
                });

                mediaAuxiliar.push(`${AWS_S3}${key}`);
            }
            ticket.media = mediaAuxiliar;

            const res = await ApiV2.put(
                `/tickets/update/${ticketId}`,
                { ...ticket },
                HEADERS()
            );
            dispatch({
                type: UPDATE_TICKET,
                payload: res.data.data,
                successMessage: 'Tickets.Updated',
            });

            setSnackbarInterval();
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearTicketState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    const clearSnackbars = () =>
        dispatch({
            type: CLEAR_SNACKBARS,
        });

    const setSnackbarInterval = () => setTimeout(() => clearSnackbars(), 1000);

    return (
        <TicketContext.Provider
            value={{
                loading: state.loading,
                tickets: state.tickets,
                ticket: state.ticket,
                error: state.error,
                count: state.count,
                notificationTickets: state.notificationTickets,
                total: state.total,
                success: state.success,
                getTickets,
                createTicket,
                getTicketsRockstar,
                getTicket,
                deleteTicket,
                updateTicket,
                clearTicketState,
                setLoading,
                AdvancedResults,
                getTicketsByUser,
                getTicketsByGroup,
                getTicketsByStore,
                updateNotificationTickets,
                updateList,
                getTicketsV2,
                deleteTickets,
            }}
        >
            {props.children}
        </TicketContext.Provider>
    );
};

export default TicketState;
