import React, { useReducer } from 'react';
import NotificationContext from './notificationContext';
import NotificationReducer from './notificationReducer';
import api from '../../api/api';
import {
    GET_NOTIFICATIONS,
    UPDATE_NOTIFICATION_VIEW,
    UPDATE_NOTIFICATION,
    SET_LOADING,
    UPDATE_NOTIFICATION_LIST,
    GET_NOTIFICATIONS_BY_USER,
    CREATE_NOTIFICATION,
    GET_ALL_NOTIFICATIONS_BY_USER,
    GET_ALL_NOTIFICATIONS,
    CLEAR_STATE,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const NotificationState = (props) => {
    const initialState = {
        notifications: [],
        allNotifications: [],
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(NotificationReducer, initialState);

    const updateList = async (notification) =>
        dispatch({ type: UPDATE_NOTIFICATION_LIST, payload: notification });

    const createNotification = async (notification) => {
        setLoading();
        try {
            const res = await api.post(
                `/notifications`,
                { ...notification },
                HEADERS()
            );
            dispatch({ type: CREATE_NOTIFICATION, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const updateNotificationsView = async (data) => {
        setLoading();
        try {
            dispatch({ type: UPDATE_NOTIFICATION_VIEW, payload: data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Notifications
    const getNotificationsByUser = async (user, isRead) => {
        setLoading();
        try {
            const res = await api.post(
                `/notifications/notificationsByUser`,
                { user, isRead },
                HEADERS()
            );
            if (isRead) {
                dispatch({
                    type: GET_ALL_NOTIFICATIONS_BY_USER,
                    payload: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_NOTIFICATIONS_BY_USER,
                    payload: res.data.data,
                });
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Notifications Super admin
    const getNotificationsSuperAdmin = async (isRead, groupId) => {
        setLoading();

        try {
            const res = await api.post(
                `/notifications/superadmin`,
                { isRead, groupId },
                HEADERS()
            );
            if (isRead) {
                dispatch({
                    type: GET_ALL_NOTIFICATIONS,
                    payload: res.data.data,
                });
            } else {
                dispatch({ type: GET_NOTIFICATIONS, payload: res.data.data });
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Notifications Admin
    const getNotificationsAdmin = async (isRead, stores) => {
        setLoading();

        try {
            const res = await api.post(
                `/notifications/admin`,
                { isRead, stores },
                HEADERS()
            );
            if (isRead) {
                dispatch({
                    type: GET_ALL_NOTIFICATIONS,
                    payload: res.data.data,
                });
            } else {
                dispatch({ type: GET_NOTIFICATIONS, payload: res.data.data });
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Comments
    const updateNotification = async (notificationId) => {
        setLoading();
        try {
            await api.put(
                `/notifications/${notificationId}`,
                { isRead: true },
                HEADERS()
            );
            dispatch({ type: UPDATE_NOTIFICATION, payload: notificationId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const setLoading = () => dispatch({ type: SET_LOADING });

    //Clear State
    const clearNotificationState = () => dispatch({ type: CLEAR_STATE });

    return (
        <NotificationContext.Provider
            value={{
                loading: state.loading,
                notifications: state.notifications,
                allNotifications: state.allNotifications,
                error: state.error,
                updateNotification,
                updateNotificationsView,
                getNotificationsByUser,
                createNotification,
                updateList,
                getNotificationsSuperAdmin,
                getNotificationsAdmin,
                clearNotificationState,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
};

export default NotificationState;
