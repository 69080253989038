import { Dispatch } from 'react';
import { ApiV2 } from 'src/api/apiv2';
import { HEADERS } from 'src/constants/headers';
import { SET_LOADING } from 'src/contexts/types';
import { TEvaluationActionTypes } from '../types';
import { handleError } from 'src/contexts/utils/handleError';
import { DELETE_EVALUATION } from '../constants/actions';

/**
 *
 * Will delete an evaluation based on the ID.
 * @param {Dispatch<TEvaluationActionTypes>} dispatch
 * @param {string} evaluationId
 */
export const deleteEvaluation = async (
    dispatch: Dispatch<TEvaluationActionTypes>,
    evaluationId: string
) => {
    dispatch({ type: SET_LOADING });
    try {
        const res = await ApiV2.delete(
            `/evaluations/${evaluationId}`,
            HEADERS()
        );
        dispatch({ type: DELETE_EVALUATION, payload: res.data.deletedId });
    } catch (error) {
        const err: any = error;
        dispatch(handleError(err));
    }
};
