import { TMetaDefaultState, TMetaPayloads } from '../types';

export const updateMetaState = (
    state: TMetaDefaultState,
    payload: TMetaPayloads
) => ({
    ...state,
    loading: false,
    error: null,
    ...payload,
});
