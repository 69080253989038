import React, { useReducer } from 'react';
import ServiceContext from './serviceContext';
import ServiceReducer from './serviceReducer';
import api from '../../api/api';
import {
    GET_SERVICES,
    CREATE_SERVICE,
    GET_SERVICE,
    DELETE_SERVICE,
    UPDATE_SERVICE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const ServiceState = (props) => {
    const initialState = {
        services: [],
        service: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(ServiceReducer, initialState);

    //Get Services
    const getServices = async (query) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(`/services?sort=name${query}`, HEADERS());
            dispatch({ type: GET_SERVICES, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Service
    const getService = async (serviceId) => {
        setLoading();
        try {
            const res = await api.get(`/services/${serviceId}`, HEADERS());
            dispatch({ type: GET_SERVICE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Service
    const deleteService = async (serviceId) => {
        setLoading();
        try {
            const res = await api.delete(`/services/${serviceId}`, HEADERS());
            dispatch({ type: DELETE_SERVICE, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Service
    const createService = async (service) => {
        clearServiceState();
        setLoading();
        try {
            const res = await api.post(`/services`, { ...service }, HEADERS());
            dispatch({ type: CREATE_SERVICE, payload: res.data.data });
        } catch (error) {
            dispatch({ type: SET_ERROR, payload: err.response.data.error });
        }
    };

    //Update Service
    const updateService = async (service, serviceId) => {
        setLoading();
        try {
            const res = await api.put(
                `/services/${serviceId}`,
                { ...service },
                HEADERS()
            );
            dispatch({ type: UPDATE_SERVICE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearServiceState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <ServiceContext.Provider
            value={{
                loading: state.loading,
                services: state.services,
                service: state.service,
                error: state.error,
                getServices,
                createService,
                getService,
                deleteService,
                updateService,
                clearServiceState,
                setLoading,
            }}
        >
            {props.children}
        </ServiceContext.Provider>
    );
};

export default ServiceState;
