import { TMetaDefaultState, TMetaActionTypes } from './types';
import {
    GET_META_ADS_CAMPAIGNS,
    GET_META_ADS_ADSETS,
    GET_META_ADS_ADS,
    GET_META_ADS_INSIGHTS,
} from './constants/actions';
import { updateMetaState } from './utils';
import { CLEAR_STATE, SET_LOADING } from '../types';

export default (
    state: TMetaDefaultState,
    action: TMetaActionTypes
): TMetaDefaultState => {
    switch (action.type) {
        case GET_META_ADS_CAMPAIGNS:
            return updateMetaState(state, {
                campaigns: action.payload.campaigns,
                loading: false,
            });
        case GET_META_ADS_ADSETS:
            return updateMetaState(state, {
                adsets: action.payload.adsets,
                loading: false,
            });
        case GET_META_ADS_ADS:
            return updateMetaState(state, {
                ads: action.payload.ads,
                loading: false,
            });
        case GET_META_ADS_INSIGHTS:
            return updateMetaState(state, {
                insights: action.payload.insights,
                loading: false,
            });
        case CLEAR_STATE:
            return updateMetaState(state, {
                campaigns: [],
                adsets: [],
                ads: [],
                insights: [],
                loading: false,
                error: null,
                success: null,
            });
        case SET_LOADING:
            return updateMetaState(state, {
                loading: true,
            });
        default:
            return state;
    }
};
