import React, { useReducer } from 'react';
import FinancialInstitutionContext from './financialInstitutionContext';
import FinancialInstitutionReducer from './financialInstitutionReducer';
import api from '../../api/api';
import {
    GET_FINANCIAL_INSTITUTIONS,
    CREATE_FINANCIAL_INSTITUTION,
    GET_FINANCIAL_INSTITUTION,
    DELETE_FINANCIAL_INSTITUTION,
    UPDATE_FINANCIAL_INSTITUTION,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const FinancialInstitutionState = (props) => {
    const initialState = {
        financialInstitutions: [],
        financialInstitution: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(
        FinancialInstitutionReducer,
        initialState
    );

    //Get FinancialInstitutions
    const getFinancialInstitutions = async (query) => {
        setLoading();
        try {
            if (!query) query = '';
            const res = await api.get(
                `/financialInstitutions?sort=name${query}`,
                HEADERS()
            );
            dispatch({
                type: GET_FINANCIAL_INSTITUTIONS,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get FinancialInstitution
    const getFinancialInstitution = async (financialInstitutionId) => {
        setLoading();
        try {
            const res = await api.get(
                `/financialInstitutions/${financialInstitutionId}`,
                HEADERS()
            );
            dispatch({
                type: GET_FINANCIAL_INSTITUTION,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete FinancialInstitution
    const deleteFinancialInstitution = async (financialInstitutionId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/financialInstitutions/${financialInstitutionId}`,
                HEADERS()
            );
            dispatch({
                type: DELETE_FINANCIAL_INSTITUTION,
                payload: res.data.deletedId,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create FinancialInstitution
    const createFinancialInstitution = async (financialInstitution) => {
        clearFinancialInstitutionState();
        setLoading();
        try {
            const res = await api.post(
                `/financialInstitutions`,
                { ...financialInstitution },
                HEADERS()
            );
            dispatch({
                type: CREATE_FINANCIAL_INSTITUTION,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update FinancialInstitution
    const updateFinancialInstitution = async (
        financialInstitution,
        financialInstitutionId
    ) => {
        setLoading();
        try {
            const res = await api.put(
                `/financialInstitutions/${financialInstitutionId}`,
                { ...financialInstitution },
                HEADERS()
            );
            dispatch({
                type: UPDATE_FINANCIAL_INSTITUTION,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearFinancialInstitutionState = () =>
        dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <FinancialInstitutionContext.Provider
            value={{
                loading: state.loading,
                financialInstitutions: state.financialInstitutions,
                financialInstitution: state.financialInstitution,
                error: state.error,
                getFinancialInstitutions,
                createFinancialInstitution,
                getFinancialInstitution,
                deleteFinancialInstitution,
                updateFinancialInstitution,
                clearFinancialInstitutionState,
                setLoading,
            }}
        >
            {props.children}
        </FinancialInstitutionContext.Provider>
    );
};

export default FinancialInstitutionState;
