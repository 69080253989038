import React, { useReducer } from 'react';
import UserContext from './userContext';
import UserReducer from './userReducer';
import api from '../../api/api';
import {
    GET_USERS,
    GET_USER,
    GET_AGENTS,
    UPDATE_USER,
    DELETE_USER,
    CREATE_USER,
    CLEAR_STATE,
    SET_LOADING,
    SET_AGENT,
    GET_AGENTS_FORM,
    CLEAR_OPERATORS,
    GET_OPERATORS,
    CLEAR_STATE_AGENTS_FORM,
} from '../types';
import { HEADERS } from '../../constants/headers';
import axios from 'axios';
import { USER_LEVEL_ACCESS } from 'src/components/access';
import { handleError } from '../utils/handleError';

const UserState = (props) => {
    const initialState = {
        users: [],
        user: {},
        loading: false,
        error: null,
        success: null,
        count: null,
        agents: [],
        operators: [],
        agentsForm: [],
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const getUsers = async (values) => {
        setLoading();
        try {
            let res = await api.post(
                `/users/usersAr`,
                { ...values },
                HEADERS()
            );

            dispatch({
                type: GET_USERS,
                payload: res.data.data,
                count: res.data.pagination.total || 0,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const setAgent = async (user) =>
        dispatch({ type: SET_AGENT, payload: user });

    const getAgents = async (query = {}) => {
        clearUserState();
        setLoading();
        try {
            const res = await api.post(
                `/users/searchDocuments`,
                { ...query, accessLevel: USER_LEVEL_ACCESS },
                HEADERS()
            );
            let { pagination = {}, results = [] } = res?.data;
            dispatch({ type: GET_AGENTS, payload: results, pagination });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getSupportTicketsUsers = async (query = {}) => {
        clearUserState();
        setLoading();
        try {
            const res = await api.post(
                `/users/searchDocuments`,
                { ...query },
                HEADERS()
            );
            let { pagination = {}, results = [] } = res?.data;
            dispatch({
                type: GET_AGENTS,
                payload: results,
                pagination,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getAgentsForm = async (query = {}) => {
        clearStateAgentsForm();
        setLoading();
        try {
            const res = await api.post(
                `/users/searchDocuments`,
                { ...query, accessLevel: USER_LEVEL_ACCESS },
                HEADERS()
            );
            let { pagination = {}, results = [] } = res?.data;
            dispatch({ type: GET_AGENTS_FORM, payload: results, pagination });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getOperators = async (query) => {
        if (!query) query = '';

        clearOperators();
        setLoading();
        try {
            const res = await api.get(
                `/users/agents?sort=name&tier=62f68a10ab36e6c1394c9ce2&isActive=true${query}`,
                HEADERS()
            );
            dispatch({ type: GET_OPERATORS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Single Item by ID
    const getUser = async (userId) => {
        setLoading();
        try {
            const res = await api.get(`/users/${userId}`, HEADERS());
            dispatch({
                type: GET_USER,
                payload: res.data.data,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete User
    const deleteUser = async (userId) => {
        setLoading();
        try {
            const res = await api.delete(`/users/${userId}`, HEADERS());
            dispatch({ type: DELETE_USER, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create User
    const createUser = async (user) => {
        clearUserState();
        setLoading();
        try {
            const res = await api.post(`/users`, { ...user }, HEADERS());
            dispatch({ type: CREATE_USER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update User
    const updateUser = async (user, userId, file) => {
        setLoading();
        try {
            let res = '';
            if (file) {
                const uploadConfig = await api.post(
                    '/uploads/image',
                    { type: file.type, fileName: file.name },
                    HEADERS()
                );

                await axios.put(uploadConfig.data.url, file, {
                    headers: { 'Content-Type': file ? file.type : null },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.put(
                    `/users/${userId}`,
                    { ...user, image: dataKey },
                    HEADERS()
                );
            } else {
                res = await api.put(`/users/${userId}`, { ...user }, HEADERS());
            }
            dispatch({ type: UPDATE_USER, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearUserState = () => dispatch({ type: CLEAR_STATE });
    const clearStateAgentsForm = () =>
        dispatch({ type: CLEAR_STATE_AGENTS_FORM });
    const clearOperators = () => dispatch({ type: CLEAR_OPERATORS });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <UserContext.Provider
            value={{
                loading: state.loading,
                users: state.users,
                user: state.user,
                agents: state.agents,
                error: state.error,
                count: state.count,
                operators: state.operators,
                agentsForm: state.agentsForm,
                success: state.success,

                getUsers,
                getAgents,
                getUser,
                getOperators,
                deleteUser,
                createUser,
                updateUser,
                clearUserState,
                setLoading,
                getAgentsForm,
                setAgent,
                getSupportTicketsUsers,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserState;
