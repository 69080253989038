import React, { useReducer } from 'react';
import VehicleContext from './vehicleContext';
import VehicleReducer from './vehicleReducer';
import api from '../../api/api';
import {
    GET_VEHICLES,
    GET_VEHICLE,
    DELETE_VEHICLE,
    UPDATE_VEHICLE,
    CREATE_VEHICLE,
    SET_LOADING,
    CLEAR_STATE,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { DEFAULT_VEHICLE_STATE } from './constants';
import { handleError } from '../utils/handleError';

const VehicleState = (props) => {
    const [state, dispatch] = useReducer(VehicleReducer, DEFAULT_VEHICLE_STATE);

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/vehicles/vehiclesAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1&searchIndex=model`,
                HEADERS()
            );
            dispatch({
                type: GET_VEHICLES,
                payload: res.data.data,
                count: res.data.count,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getVehicles = async (query = {}) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/vehicles/searchDocuments`,
                query,
                HEADERS()
            );
            let { pagination = {}, results = [] } = res?.data;
            dispatch({
                type: GET_VEHICLES,
                payload: results,
                pagination,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Vehicles
    const getVehicle = async (vehicleId) => {
        clearState();
        setLoading();
        try {
            const res = await api.get(`/vehicles/${vehicleId}`, HEADERS());
            dispatch({ type: GET_VEHICLE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Vehicle
    const deleteVehicle = async (vehicleId) => {
        setLoading();
        try {
            const res = await api.delete(`/vehicles/${vehicleId}`, HEADERS());
            dispatch({ type: DELETE_VEHICLE, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Vehicle
    const createVehicle = async (vehicle) => {
        clearState();
        setLoading();
        try {
            const res = await api.post(`/vehicles`, { ...vehicle }, HEADERS());
            dispatch({ type: CREATE_VEHICLE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Vehicle
    const updateVehicle = async (
        vehicle,
        vehicleId,
        { needLoading = true, needClearState = true }
    ) => {
        if (needClearState) clearState();
        if (needLoading) setLoading();
        try {
            const res = await api.put(
                `/vehicles/${vehicleId}`,
                { ...vehicle },
                HEADERS()
            );
            dispatch({ type: UPDATE_VEHICLE, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <VehicleContext.Provider
            value={{
                loading: state.loading,
                vehicles: state.vehicles,
                vehicle: state.vehicle,
                error: state.error,
                count: state.count,
                getVehicles,
                getVehicle,
                createVehicle,
                AdvancedResults,
                deleteVehicle,
                updateVehicle,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </VehicleContext.Provider>
    );
};

export default VehicleState;
