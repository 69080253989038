import * as Sentry from '@sentry/browser';
import { createBrowserHistory } from 'history';
import {
    reactRouterV5BrowserTracingIntegration,
    withSentryRouting,
} from '@sentry/react';
import { Route } from 'react-router-dom';

export const SentryRoute = withSentryRouting(Route);
const history = createBrowserHistory();

export const initSentry = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        tracesSampleRate: 1.0,
        profilesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
        enabled: process.env.NODE_ENV !== 'development',
        integrations: [reactRouterV5BrowserTracingIntegration({ history })],
    });

    Sentry.setMeasurement('memoryUsed', 123, 'byte');
    Sentry.setMeasurement('ui.footerComponent.render', 1.3, 'second');
    Sentry.setMeasurement('localStorageRead', 4);
};

export const captureException = (error) => Sentry.captureException(error);
