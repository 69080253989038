import React, { useReducer } from 'react';
import AdCampaignContext from './adCampaignContext';
import AdCampaignReducer from './adCampaignReducer';
import api from '../../api/api';
import {
    GET_ADCAMPAIGNS,
    CREATE_ADCAMPAIGN,
    GET_ADCAMPAIGN,
    DELETE_ADCAMPAIGN,
    UPDATE_ADCAMPAIGN,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const AdCampaignState = (props) => {
    const initialState = {
        adCampaigns: [],
        adCampaign: {},
        loading: false,
        error: null,
        count: null,
    };

    const [state, dispatch] = useReducer(AdCampaignReducer, initialState);

    const AdvancedResults = async (pagination, query) => {
        setLoading();
        try {
            const res = await api.get(
                `/adCampaigns?page=${pagination.page}&limit=${
                    pagination.limit
                }${query.replace(/createdAt/gi, 'startDate')}&searchType=and`,
                HEADERS()
            );
            dispatch({
                type: GET_ADCAMPAIGNS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    const getAdCampaignsQuery = async (query = '') => {
        setLoading();
        try {
            const res = await api.get(`/adCampaigns?${query}`, HEADERS());

            dispatch({
                type: GET_ADCAMPAIGNS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Recordings by store
    const getAdCampaignsByStore = async (
        stores,
        pagination,
        query,
        typeQuery
    ) => {
        setLoading();
        try {
            const res = await api.get(
                `/adCampaigns?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                HEADERS()
            );

            dispatch({
                type: GET_ADCAMPAIGNS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get AdCampaigns
    const getAdCampaigns = async (pagination, query, typeQuery) => {
        if (!query) query = '';
        setLoading();
        try {
            const res = await api.get(
                `/adCampaigns?page=${pagination.page}&searchIndex=name-store-source&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
                HEADERS()
            );
            dispatch({
                type: GET_ADCAMPAIGNS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get AdCampaign
    const getAdCampaign = async (adCampaignId) => {
        setLoading();
        try {
            const res = await api.get(
                `/adCampaigns/${adCampaignId}`,
                HEADERS()
            );
            dispatch({ type: GET_ADCAMPAIGN, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete AdCampaign
    const deleteAdCampaign = async (adCampaignId) => {
        setLoading();
        try {
            const res = await api.delete(
                `/adCampaigns/${adCampaignId}`,
                HEADERS()
            );
            dispatch({ type: DELETE_ADCAMPAIGN, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create AdCampaign
    const createAdCampaign = async (adCampaign) => {
        clearAdCampaignState();
        setLoading();
        try {
            const res = await api.post(
                `/adCampaigns`,
                { ...adCampaign },
                HEADERS()
            );
            dispatch({ type: CREATE_ADCAMPAIGN, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update AdCampaign
    const updateAdCampaign = async (adCampaign, adCampaignId) => {
        setLoading();
        try {
            const res = await api.put(
                `/adCampaigns/${adCampaignId}`,
                { ...adCampaign },
                HEADERS()
            );
            dispatch({ type: UPDATE_ADCAMPAIGN, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearAdCampaignState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <AdCampaignContext.Provider
            value={{
                loading: state.loading,
                adCampaigns: state.adCampaigns,
                adCampaign: state.adCampaign,
                error: state.error,
                count: state.count,
                getAdCampaigns,
                createAdCampaign,
                getAdCampaign,
                deleteAdCampaign,
                updateAdCampaign,
                clearAdCampaignState,
                setLoading,
                AdvancedResults,
                getAdCampaignsByStore,
                getAdCampaignsQuery,
            }}
        >
            {props.children}
        </AdCampaignContext.Provider>
    );
};

export default AdCampaignState;
