import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Routes = [
    {
        exact: true,
        path: '/create/ticket',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/ticket/TicketCreateView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/tickets/:id',
        component: lazy(() => import('src/views/ticket/TicketDetailsView')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/tickets',
        component: lazy(() => import('src/views/ticket/TicketListView')),
    },
];
