import React, { useReducer } from 'react';
import GroupContext from './groupContext';
import GroupReducer from './groupReducer';
import api from '../../api/api';
import {
    GET_GROUPS,
    CREATE_GROUP,
    GET_GROUP,
    DELETE_GROUP,
    UPDATE_GROUP,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const GroupState = (props) => {
    const initialState = {
        groups: [],
        group: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(GroupReducer, initialState);

    const getAllGroups = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            const res = await api.get('/groups?sort=name', HEADERS());

            dispatch({
                type: GET_GROUPS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Groups
    const getGroups = async (pagination, query, typeQuery) => {
        setLoading();
        try {
            let res;
            if (!query) query = '';

            if (pagination) {
                res = await api.get(
                    `/groups?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name&searchText=${query}&searchType=${typeQuery}&validation=1`,
                    HEADERS()
                );
            }
            dispatch({
                type: GET_GROUPS,
                payload: res.data.data,
                count: res.data.pagination.total,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Group
    const getGroup = async (groupId) => {
        clearGroupState();
        setLoading();
        try {
            const res = await api.get(`/groups/${groupId}`, HEADERS());
            dispatch({ type: GET_GROUP, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Group
    const deleteGroup = async (groupId) => {
        setLoading();
        try {
            const res = await api.delete(`/groups/${groupId}`, HEADERS());
            dispatch({ type: DELETE_GROUP, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Group
    const createGroup = async (group) => {
        clearGroupState();
        setLoading();
        try {
            const res = await api.post(`/groups`, { ...group }, HEADERS());
            dispatch({ type: CREATE_GROUP, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Group
    const updateGroup = async (group, groupId) => {
        setLoading();
        try {
            const res = await api.put(
                `/groups/${groupId}`,
                { ...group },
                HEADERS()
            );
            dispatch({ type: UPDATE_GROUP, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearGroupState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <GroupContext.Provider
            value={{
                loading: state.loading,
                groups: state.groups,
                group: state.group,
                error: state.error,
                count: state.count,
                getGroups,
                createGroup,
                getGroup,
                deleteGroup,
                updateGroup,
                clearGroupState,
                setLoading,
                getAllGroups,
            }}
        >
            {props.children}
        </GroupContext.Provider>
    );
};

export default GroupState;
