import React, { useReducer } from 'react';
import StatusContext from './statusContext';
import StatusReducer from './statusReducer';
import api from '../../api/api';
import {
    GET_STATUSES,
    GET_STATUS,
    CREATE_STATUS,
    DELETE_STATUS,
    UPDATE_STATUS,
    CLEAR_STATE,
    SET_LOADING,
} from '../types';
import { HEADERS } from '../../constants/headers';
import { handleError } from '../utils/handleError';

const StatusState = (props) => {
    const initialState = {
        statuses: [],
        status: {},
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(StatusReducer, initialState);

    //Get Statuses
    // statusType para ordenar los status de los diferentes modelos
    const getStatuses = async (statusType = 'lead') => {
        setLoading();
        try {
            const res = await api.get(`/status?sort=name`, HEADERS());
            dispatch({
                type: GET_STATUSES,
                payload: res.data.data,
                statusType,
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Get Status
    const getStatus = async (statusId) => {
        clearStatusState();
        setLoading();
        try {
            const res = await api.get(`/status/${statusId}`, HEADERS());
            dispatch({ type: GET_STATUS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Delete Status
    const deleteStatus = async (statusId) => {
        setLoading();
        try {
            const res = await api.delete(`/status/${statusId}`, HEADERS());
            dispatch({ type: DELETE_STATUS, payload: res.data.deletedId });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Create Status
    const createStatus = async (status) => {
        clearStatusState();
        setLoading();
        try {
            const res = await api.post(`/status`, { ...status }, HEADERS());
            dispatch({ type: CREATE_STATUS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Update Make
    const updateStatus = async (status, statusId) => {
        clearStatusState();
        setLoading();
        try {
            const res = await api.put(
                `/status/${statusId}`,
                { ...status },
                HEADERS()
            );
            dispatch({ type: UPDATE_STATUS, payload: res.data.data });
        } catch (error) {
            dispatch(handleError(error));
        }
    };

    //Clear State
    const clearStatusState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <StatusContext.Provider
            value={{
                loading: state.loading,
                statuses: state.statuses,
                status: state.status,
                error: state.error,
                getStatus,
                getStatuses,
                createStatus,
                deleteStatus,
                updateStatus,
                clearStatusState,
                setLoading,
            }}
        >
            {props.children}
        </StatusContext.Provider>
    );
};

export default StatusState;
