import { TMetaDefaultState } from '../types';

export const DEFAULT_META_STATE: TMetaDefaultState = {
    campaigns: [],
    adsets: [],
    ads: [],
    insights: [],
    loading: false,
    error: null,
    success: null,
};
